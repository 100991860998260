import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { EMPTY, Observable, Subject, catchError, map, of, switchMap, takeUntil, takeWhile, tap } from 'rxjs';

import { AppData } from 'src/app/data';
import { ImageService } from 'src/app/services/image.service';
import { Job, JobType } from '../../models/job';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.page.html',
  styleUrls: ['./job-details.page.scss'],
})
export class JobDetailsPage implements OnInit, OnDestroy{

  @Input() job: Job;
  @Input() isDetailView = false;
  public showClientId: boolean = environment.features.showClientId;
  public riderIdLabel: string = environment.translationKeys.riderId;
  public scheduledTimeLabel: string = environment.translationKeys.scheduledTime;
  public priorityLabel: string = environment.translationKeys.priority;
  public dropoffJob: Job;

  private readonly data = inject(AppData);
  private readonly destroyed$ = new Subject<boolean>();

  public readonly showFare = environment.features.calculateJobFare;
  public image: string;
  public readonly hasNetwork$ = this.data.connectionStatus$.pipe(map(value => !!value.connected));
  public errorMessage = '';

  private readonly gtm = (<any>window).dataLayer || [];

  constructor(
    public router: Router,
    private modalCtrl: ModalController,
    private imageService: ImageService,
    ) {
  }

  ngOnInit(): void {
    this.gtm.push({ event: 'custom_event', custom_event_name: 'view_ job_details' });

    if (this.job.riderImageId) {
      this.getRiderImage().subscribe();
    }

    if (environment.features.showDropoffWithPickup && this.job.jobType === JobType.Pickup) {
      this.dropoffJob = this.data.route.jobs.filter(j => j.rideId === this.job.rideId && j.jobType === JobType.DropOff)[0];
    }
  }

  getRiderImage(): Observable<string> {
    return this.hasNetwork$.pipe(
      takeWhile(hasNetwork => !hasNetwork, true),
      switchMap(hasNetwork => hasNetwork ?
        this.imageService.riderImage(this.data.sessionId, this.job.riderImageId)
        : EMPTY,
      ),
      takeWhile(image => !image, true),
      catchError(err => {
        console.error(err);
        this.errorMessage = 'An error has occurred fetching the image, please try again later.';
        return of('assets/img/no_image.svg');
      }),
      tap(res => this.image = res),
      takeUntil(this.destroyed$),
    );
  }

  async dismiss(data) {
    await this.modalCtrl.dismiss(data);
  }

  calculateJobFare() {
    if (environment.features.calculateJobFare)
      return this.job.additionalRideFare * (this.job.escorts + this.job.numberOfChildren) + this.job.rideFare;
    return this.job.rideFare;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
