import { AsyncSubject, Observable,firstValueFrom, interval, switchMap, tap, Subscription, map } from 'rxjs';
import BackgroundGeolocation, { AuthorizationStatus, Config, CurrentPositionRequest, Location, State } from "@transistorsoft/capacitor-background-geolocation";
import { Injectable } from '@angular/core';
import { LocationUtilityService, MetersPerSecToMilesPerHrMultiplier, MilesToMetersMultiplier } from './location-utility.service';
import { MapTypeEnum, NativePluginService } from './plugin/native-plugins.service';

import { Coordinate } from '../models/location';
import { GoogleApiService } from './google-api.service';
import { GpsReport } from '../models/gps-report';
import { environment } from 'src/environments/environment';
import { Network } from '@capacitor/network';
import { TrackJS } from 'trackjs';
import { Route } from '../models/route';
import { Device } from '../models/device';
import { DeviceContext, OperatorContext } from '../models/location-context';
import { AppData } from '../data';
import { Geolocation } from '@capacitor/geolocation';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LocationService {

  /*
   * Async subjects act kind of like promises in that they will only
   * emit a value once complete() has been called. Since the initialize
   * is a promise, we can create a subject we listen to  in order to wait
   * until initialization is done before other work is performed.
   * We must do this because the constructor cannot be made async.
   */
  private readonly initialized = new AsyncSubject<boolean>();
  private readonly gtm = (<any>window).dataLayer || [];

  private webGeoCheck: Subscription | null;
  private lastKnownLocations = [];
  private retryOnLocationListener = 0;

  /**
   * Returns true when the location service has been initialized,
   * if the initialization fails, will return false.
   */
  get initialized$(): Promise<boolean> {
    return firstValueFrom(this.initialized.asObservable());
  }

  mockLocation: boolean = false;
  lastLocation: Location | null = null;

  private lastUserEnteredValue: { bbgo: number, user: number, adjustment: number } = { bbgo: 0, user: 0, adjustment: 0 };
  private lastBBGOOdometerValue: number = 0;

  private readonly blankGPSReport: GpsReport = {
    accuracy: 0,
    direction: 0,
    heading: 0,
    latitude: 0,
    longitude: 0,
    dateTimeReported: new Date(),
    occuranceDate: new Date(),
    speed: 0,
    odometer: 0,
    milesFromPrevious: 0,
  };

  // a little messy, but keep in mind that this gets set from the server upon login
  public paraScopeGpsReportingInterval = 60000;

  // this is set when we navigate so that we can bring app to front when we arrive
  destination: Coordinate;

  appName = environment.appName;
  backgroundPermissionTitle = `Allow ${this.appName} to access to this device's location in the background?`;

  //  translate service is not correctly translating these,  so for the time being hard setting to english
  //  TODO: handle spanish
  backgroundPermissionRationale = {
    title: this.backgroundPermissionTitle,
    message: `In order to track your activity in the background, please enable {backgroundPermissionOptionLabel} location permission`,
    positiveAction: `Change to {backgroundPermissionOptionLabel}`,
    negativeAction: 'Cancel',
  };

  /** IOS */
  locationAuthorizationAlert = {
    titleWhenNotEnabled: this.backgroundPermissionTitle,
    titleWhenOff: this.backgroundPermissionTitle,
    instructions: 'In order to track your activity in the background, please enable always in the Location Services settings',
    cancelButton: 'Cancel',
    settingsButton: 'Settings',
  };

  private BBGEOConfig: Config = {

    /**
     * Distance Filter
     * Default: 10
     * The minimum distance (measured in meters) a device must move horizontally before an update event is generated.
     * However, by default, distanceFilter is elastically auto-calculated by the plugin: When speed increases, distanceFilter increases;
     * when speed decreases, so too does distanceFilter.
     *
     * We might want to increased if customer complaint of drift when they are not moving
     */
    distanceFilter: 10,

    /**
     * DesiredOdometerAccuracy
     * Default: 100, Location accuracy threshold in meters for odometer calculations.
     * The maximum location accuracy allowed for a location to be used for Location.odometer calculations.
     * Defaults to 100. If a location arrives having accuracy > desiredOdometerAccuracy, that location will not be used to update the odometer.
     * If you only want to calculate odometer from GPS locations, you could set desiredOdometerAccuracy: 10.
     * This will prevent odometer updates when a device is moving around indoors, in a shopping mall, for example.
     *
     * May want to look at analytics to determine device specific settings
     */
    desiredOdometerAccuracy: 30,

    /**
     * allowIdenticalLocations
     * Default: false
     * The Android plugin will ignore a received location when it is identical to the last location.
     * Set true to override this behavior and record every location, regardless if it is identical to the last location.
     */
    allowIdenticalLocations: true,

    /**
     * logLevel
     * Controls the volume of recorded events in the plugin's logging database.
     * BackgroundGeolocation contains powerful logging features.
     * By default, the plugin boots with a value of BackgroundGeolocation.LOG_LEVEL_OFF,
     * storing logMaxDays (default 3) days worth of logs in its SQLite database.
     */
    logLevel: BackgroundGeolocation.LOG_LEVEL_ERROR,
    /**
     * maxRecordsToPersist
     * Maximum number of records to persist in plugin's SQLite database.
     * Default -1 means no limit. To disable persisting locations, set this to 0
     */
    maxRecordsToPersist: 0,

    /**
     * preventSuspend
     * [iOS only] Prevent iOS from suspending your application in the background after location-services have been switched off.
     * Defaults to false. Set true to prevent iOS from suspending your application after location-services have been switched off
     * while running in the background. Must be used in conjunction with a heartbeatInterval.
     */
    preventSuspend: true,
    /**
     * heartbeatInterval
     * Controls the rate (in seconds) the BackgroundGeolocation.onHeartbeat event will fire.
     * ⚠️ Warning:
     * On iOS the BackgroundGeolocation.onHeartbeat event will fire only when configured with preventSuspend true.
     * Android minimum interval is 60 seconds. It is impossible to have a heartbeatInterval faster than this on Android.
     */
    heartbeatInterval: 60,

    /**
     * 🧪 speedJumpFilter
     * Android-only Experimental filter to ignore anomalous locations that suddenly jump an unusual distance from last.
     * The SDK will calculate an apparent speed and distance relative to last known location.
     * If the location suddenly teleports from last location, it will be ignored.
     * The measurement is in meters/second.
     * The default is to throw away any location which apparently moved at 300 meters/second from last known location.
     *
     * This may be something we may want to adjust
     */
    speedJumpFilter: 40, // Default: 300

    /** (Android 11+) Configure the dialog presented to the user when Always location permission is requested. */

    backgroundPermissionRationale: this.backgroundPermissionRationale,

    /** IOS */
    locationAuthorizationAlert: this.locationAuthorizationAlert,

    disableStopDetection: true,
    pausesLocationUpdatesAutomatically: false,
    notification: {
      title: environment.appName,
      text: `${ environment.appName } is running in the background`,
    },
    isMoving: true,

  };

  constructor(
    private pluginService: NativePluginService,
    private googleSvc: GoogleApiService,
    private data: AppData,
    private diagnostic: Diagnostic,
    private platform: Platform,
  ) { }

  /**
   * Gets called in the constructor and should not be called from anywhere else
   * in order not to generate unwanted listeners
   * @returns Promise<boolean>: whether the service is done with setup
   */
  public async init(loading?: HTMLIonLoadingElement): Promise<boolean> {
    try {
      if (!this.pluginService.isNativePlatform) {
        const report = await this.getGPSReport();
        this.data.set('gpsReport', report);
        if (!this.webGeoCheck) { this.webGeoCheck = this.listenForWeb().subscribe(); }
        this.initialized.next(true);
        this.initialized.complete();
        return true;
      }
      //  if background is not enabled, BackgroundGeolocation fails and crashes
      // the app in Android 14, so need to check for permission first, and
      // if not granted, stop trying to initialize.
      if (this.platform.is('android')) {
        await this.platform.ready();
        const status = await this.diagnostic.getLocationAuthorizationStatuses()
          .catch(err => { console.log(err); return null; });

        if (loading) { loading.message = `Background Location Status: ${status?.ACCESS_BACKGROUND_LOCATION}`; }
        if (status?.ACCESS_BACKGROUND_LOCATION !== 'GRANTED') {
          await Geolocation.getCurrentPosition();
        };
      }

      let state: State = await BackgroundGeolocation.ready(this.BBGEOConfig);

      if (!(await Geolocation.checkPermissions()
        .then(state => state.location === 'granted')
        .catch(err => { console.error(err); return false; }))
      ) {
        await Geolocation.getCurrentPosition().catch(err => { console.error(err);});
      }

      const providerState = await BackgroundGeolocation.getProviderState();
      if (loading) { loading.message = `Background Location Provider State: ${providerState?.status}`; }
      if (providerState.status !== 3) {
        console.debug((await BackgroundGeolocation.getProviderState()).status);
        const res = await BackgroundGeolocation.requestPermission();
        if (res !== 3) throw new Error('Location permissions needs to be set to always for proper functioning of the application');
      }

      await this.resetListeners();

      //  disabling stop detection on devices with no gyroscope as it seems to prevent
      //  them from tracking after stopping
      const sensors = await BackgroundGeolocation.getSensors();
      if (loading) { loading.message = `Found Gyroscope: ${sensors.gyroscope}`; }
      if (environment.trackJS?.token) { TrackJS.addMetadata('hasGyroscope', sensors.gyroscope ? 'true' : 'false'); }
      this.gtm.push({ 'gyroscope': sensors.gyroscope });

      if (loading) { loading.message = `... Setting BBGO config`; }
      state = await BackgroundGeolocation.setConfig(this.BBGEOConfig);

      if (loading) { loading.message = `state enabled: ${state?.enabled}`; }
      if (!state?.enabled) {
        state = await BackgroundGeolocation.start();
      }

      if (loading) { loading.message = `... Getting Current Location`; }
      const report = await this.getGPSReport();
      console.debug('initial GPS Report', report);
      this.data.set('gpsReport', report);

      if (loading) { loading.message = `... Starting location change listener`; }

      //  Need to create Promise because the promise never actually resolves
      //  need to get resolves out of the callbacks
      new Promise(async (resolve, reject) => {
        await BackgroundGeolocation.changePace(
          true,
          () => {
            loading.message = `Change pace successful`;
            resolve(true);
          },
          err => {
            console.error(err);
            reject(err);
          },
        );
      });

      this.initialized.next(true);
      if (loading) { loading.message = `Location Init: ${state.enabled}`; }
      return state.enabled;
    } catch (err) {
      console.error('---------------> initialization failed', err);
      this.initialized.next(false);
      return false;
    }
    finally {
      this.initialized.complete();
    }
  }

  convertMetersToMiles(meters: number): number {
    return Math.round(meters / MilesToMetersMultiplier);
  }

  async onLocation(location: Location) {
    TrackJS.console.log('onLocation Fn');
    try {
      if (!location) {
        TrackJS.track('No location');
        return;
      }
      this.lastLocation = location || <Location>{ activity: {}, coords: {} };
      const {
        activity: { type, confidence },
        coords: { accuracy, latitude, longitude },
        odometer,
        timestamp,
      } = location;

      let {
        coords: { speed, heading },
      } = location;

      speed = (speed || 0) >= 0
        ? Math.min(999, 999, Math.round((speed || 0) * MetersPerSecToMilesPerHrMultiplier))
        : 0;

      const lastUserEnteredValue = this.lastUserEnteredValue?.user ?? 0;
      this.lastBBGOOdometerValue = this.convertMetersToMiles(odometer);
      const diff = this.lastBBGOOdometerValue - (this.lastUserEnteredValue?.bbgo ?? lastUserEnteredValue);
      const newOdometerValue = lastUserEnteredValue + diff;

      heading = heading >= 0 ? Math.round(heading) : null;
      const date = new Date();

      const gpsReport = {
        accuracy,
        direction: 100,
        heading, latitude, longitude,
        dateTimeReported: date,
        occuranceDate: new Date(timestamp),
        speed,
        odometer: newOdometerValue,
        milesFromPrevious: Math.abs((this.data.gpsReport.odometer || 0) - newOdometerValue),
      };
      this.data.set('gpsReport', gpsReport);
    } catch (err) {
      TrackJS.track(err);
    }
  }

  async checkPermissions(): Promise<AuthorizationStatus | void> {
    if (!this.pluginService.isNativePlatform) { return Promise.resolve(); }
    return BackgroundGeolocation.requestPermission();
  }

  async stop(): Promise<State | void> {
    await this.initialized$;
    console.warn('stopping');
    if (!this.pluginService.isNativePlatform) { return Promise.resolve(); }
    return BackgroundGeolocation.stop();
  }

  public async changePace(pace: boolean) {
    await this.initialized$;
    if (!this.pluginService.isNativePlatform) { return; }
    await BackgroundGeolocation.changePace(pace);
    console.warn('[BackgroundGeolocation] pace moving: ' + pace);
    return;
  }

  private async getGPSReport() {
    let gpsReport!: GpsReport;
    if (this.pluginService.isNativePlatform) {
      gpsReport = await this.getNativeReport();
    } else if (this.mockLocation) {
      gpsReport = await this.getGPSReportMock();
    } else {
      gpsReport = await this.getGPSReportWeb();
    }
    return gpsReport || {} as GpsReport;
  }

  async getNativeReport(): Promise<GpsReport> {
    const options: CurrentPositionRequest = {
      timeout: 30,          // 30 second timeout to fetch location
      maximumAge: 20000,    // Accept the last-known-location if not older than 5000 ms.
      desiredAccuracy: 30,
    };
    const currentPosition: Location = await BackgroundGeolocation.getCurrentPosition(options);
    this.lastLocation = currentPosition;
    const {
      activity: { type, confidence },
      coords: { accuracy, latitude, longitude },
    } = currentPosition;

    let {
      coords: { speed, heading },
      odometer,
    } = currentPosition;

    speed = type == 'still' && confidence >= 75
      ? 0
      : Math.round(!isNaN(speed) && speed >= 0 ? speed * MetersPerSecToMilesPerHrMultiplier : 0);

    odometer = Math.round(odometer / MilesToMetersMultiplier);

    heading = heading >= 0 ? Math.round(heading) : null;
    const date = new Date();

    return {
      accuracy,
      direction: 100,
      heading, latitude, longitude,
      dateTimeReported: date,
      occuranceDate: date,
      speed,
      odometer,
      milesFromPrevious: 0,
    };
  }

  private listenForWeb(): Observable<GpsReport> {
    return interval(30_000).pipe(
      switchMap(() => this.getGPSReportWeb()),
      map(({ latitude, longitude, odometer, ...params }) => ({
        ...params,
        //  simulating car movement
        latitude: latitude + .01,
        longitude: longitude + .01,
        odometer: odometer + 1,
      })),
      tap(gpsReport => {
        this.data.set('gpsReport', gpsReport);
      }),
    );
  }

  private async getWebPosition(): Promise<GeolocationPosition> {
    if (!navigator?.geolocation?.getCurrentPosition) {
      console.warn('don\'t have access to navigator or browser does not seem to support navigator.geolocation.getCurrentPosition');
      throw new Error('can\'t find navigator.geolocation.getCurrentPosition');
    }
    return new Promise(resolve => {
      //  allow service to return an old report that is up to 30 seconds old
      //  default timeout = 0 which means that it can never return a cached location
      navigator.geolocation.getCurrentPosition(
        position => resolve(position),
        error => {
          console.error(error);
          const position = {
            coords: { latitude: 0, longitude: 0, accuracy: 0, heading: 0, altitude: 0,
              altitudeAccuracy: 0, speed: 0 },
            timestamp: new Date().valueOf(),
          };
          resolve(<GeolocationPosition>position);
          return position;
        },
        { timeout: 1000, maximumAge: 30000 },
      );
    });
  }

  private async getGPSReportWeb(): Promise<GpsReport> {
    try {
      const date = new Date();

      const location: GeolocationPosition = await this.getWebPosition();
      const { latitude, longitude, accuracy, heading, speed } = location?.coords || {};

      return {
        latitude,
        longitude,
        accuracy,
        direction: 100,
        dateTimeReported: date,
        occuranceDate: location?.timestamp ? new Date(location.timestamp) : date,
        speed: speed || 0,
        odometer: this.data.gpsReport.odometer,
        milesFromPrevious: 0,
        heading: heading || 100,
      };
    } catch (error) {
      console.error(error);
      return Promise.resolve(this.blankGPSReport);
    }
  }

  private async getGPSReportMock(): Promise<GpsReport> {
    try {
      return {
        accuracy: 0,
        direction: 100,
        heading: 0,
        latitude: 0,
        longitude: 0,
        dateTimeReported: new Date(),
        occuranceDate: new Date(),
        speed: 0,
        odometer: 0,
        milesFromPrevious: 0,
      };
    } catch (error) {
      console.error(error);
      return this.blankGPSReport;
    }
  }

  hasArrived(destination: Coordinate): boolean {
    if (!destination) { return false; }
    const gpsReport = this.data.gpsReport;
    const euclideanDistanceInMiles = LocationUtilityService.euclideanDistanceInMiles(
      gpsReport.latitude, gpsReport.longitude, destination.latitude, destination.longitude,
    );
    if (euclideanDistanceInMiles < 0.5) { return true; }
    return false;
  }

  /**
   * This should only be called when user is adjusting the odometer manually to adjust for error.
   * @param odometer odom is in miles and needs to be converted to meters.
   */
  async setOdometer(odometer: number): Promise<void> {
    await this.initialized$;
    const report = this.data.gpsReport;
    this.lastUserEnteredValue = {
      user: odometer,
      bbgo: this.lastBBGOOdometerValue,
      adjustment: odometer - this.lastBBGOOdometerValue,
    };
    this.data.set('gpsReport', { ...report, odometer });
  }

  milesToMeters(miles: number): number {
    return miles * MilesToMetersMultiplier;
  }


  /**
   * Opens Google Maps and initializes it in navigate mode for a given location.
   * @param Coordinate - the location coordinates to navigate to.
   */
  async navigateToDestination(coordinate: Coordinate | null): Promise<any> {
    if (!coordinate) { return null; }
    await this.initialized$;
    this.destination = coordinate;
    return this.pluginService.launchMappingApplication(coordinate.latitude, coordinate.longitude, MapTypeEnum.TurnByTurnDirections);
  }

  /**
   * Calculate the arrival time using either the google calculate eta service or a goofy estimate algorithm.
   * @param lat2 number - end location latitude
   * @param lng2 number - end location longitude
   * @param fromDate Date - the date to offset from.
   */
  async calculateArrivalTime(
    lat2: number,
    lng2: number,
    fromDate: Date = null,
  ): Promise<Date | null> {
    try {
      await this.initialized$;
      const gpsReport: GpsReport = this.data.gpsReport;
      const eta: Date = await this.googleSvc.calculateETA(gpsReport.latitude, gpsReport.longitude, lat2, lng2, fromDate);
      return eta;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * This opens Google Maps and shows a location.
   * @param coordinate Coordinate - the location coordinates to view on the map.
   * @param name string - the location name
   */
  async previewLocation(coordinate: Coordinate, name: string): Promise<any> {
    try {
      if (!coordinate) { return Promise.resolve(); }
      return this.pluginService.launchMappingApplication(coordinate.latitude, coordinate.longitude, MapTypeEnum.Geolocation);
    } catch (error) {
      console.error(error);
      return Promise.resolve();
    }
  }

  async toggleDebug(): Promise<State> {
    await this.initialized$;
    const state = await BackgroundGeolocation.getState();
    return BackgroundGeolocation.setConfig({ debug: !state.debug });
  }

  /**
   * @returns boolean success of the log
   */
  async emailLog(): Promise<boolean> {
    try {
      const Logger = BackgroundGeolocation.logger;
      // TODO: grab email from
      const success = await Logger.emailLog(environment.developerEmail);
      return success;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async logToTrackJS(original: GpsReport, sent: GpsReport) {
    if (!environment.trackJS?.token) { return; }
    try {

      //  Network Status
      await Network.getStatus()
        .then(value => { TrackJS.console.log(value); return value; })
        .catch(err => TrackJS.console.warn('networkStatus', err));

      //  Time Diff -- for staleness
      const timeDiff = sent?.dateTimeReported?.valueOf() - original?.dateTimeReported?.valueOf();
      TrackJS.console.log('timeDiff', timeDiff);

      //  Sensors
      await BackgroundGeolocation.getSensors()
        .then(value => TrackJS.console.log('sensors', value))
        .catch(err => TrackJS.console.warn('sensors', err));

      //  GPS
      const currentGPSFromBBGO = await this.getGPSReport()
        .then(value => { TrackJS.console.log('currentGPSFromBBGO', value); return value; })
        .catch(err => { TrackJS.console.warn('currentGpsFromBBGO', err); return null; });

      let distanceInMiles = 0;
      if (currentGPSFromBBGO?.latitude && currentGPSFromBBGO?.longitude && original?.latitude && original?.longitude) {
        distanceInMiles = LocationUtilityService.euclideanDistanceInMiles(original.latitude, original.longitude, currentGPSFromBBGO.latitude, currentGPSFromBBGO.longitude);
        TrackJS.console.log('distanceInMiles', distanceInMiles);
        //  105.6 feet
        //  if the diff between the 2 time values is more than 30 seconds and distance >= 105.6ft log stale gps.
        if (!isNaN(timeDiff) && timeDiff > 3000 && distanceInMiles >= .02) { TrackJS.track('GPS is Stale'); }
      }

      const odoDiff = original && sent ? sent.odometer - original.odometer : Infinity;
      let fiveAgoDiff = Infinity;
      //  5 records = 2.5 minutes worth of logs
      if (this.lastKnownLocations.length >= 5) {
        fiveAgoDiff = sent.odometer - this.lastKnownLocations[0].odometer;
      }
      if (
        Number(distanceInMiles.toFixed(0)) - odoDiff > 0
        || (sent.speed > 45 && odoDiff === 0)
        // Odo says they have gone less than 1.5 miles in 2.5 minutes when
        // BBGO has clocked a speed >= 30 within that time frame
        // 30 miles / hour = .5 miles / minute = 1.25
        || ([ ...this.lastKnownLocations, sent ].some(({ speed }) => speed >= 30) && fiveAgoDiff < 1.25)
      ) {
        TrackJS.track('ODO is Stale');
      }

      //  only keeping 5 in memory
      if (this.lastKnownLocations.length >= 5) { this.lastKnownLocations.unshift(); }
      if (!!sent) this.lastKnownLocations.push(sent);

    } catch (err) {
      TrackJS.console.warn(err);
      TrackJS.track('GPS and/or ODO check error');
    }
  }

  getAvlReport({ gpsReport, customerId, route, device }
    : { gpsReport: GpsReport, customerId: number, route: Route, device: Device })
    : { location: Location, device: DeviceContext, operator: OperatorContext }
  {
    const { platform, modelVersion: version, identity: { uuid },
      cordovaVersion: cordova, modelVersion: model, manufacturer } = device || { identity: {} };
    const { vehicleId, id: routeId, driverId } = route || {};
    const { odometer } = gpsReport;

    return {
      location: {
        age: Date.now() - (new Date(this.lastLocation?.timestamp) || new Date()).valueOf(),
        timestamp: new Date().toISOString(),
        coords: {
          altitude: this.lastLocation?.coords.altitude || 0,
          floor: this.lastLocation?.coords.floor || 0,
          altitude_accuracy: this.lastLocation?.coords.altitude_accuracy || 0,
          ...gpsReport,
        },
        battery: this.lastLocation?.battery || { level: 0, is_charging: false },
        activity: this.lastLocation?.activity || { confidence: 0, type: null },
        odometer,
        event: this.lastLocation?.event || "",
        is_moving: !!this.lastLocation?.is_moving,
        mock: false,
        uuid,
      } as Location,
      device: <DeviceContext>{ platform: platform, version, uuid, cordova, model, manufacturer },
      operator: <OperatorContext>{ customerId, driverId, vehicleId, routeId },
    };
  }

  private async resetListeners() {
    try {
      await BackgroundGeolocation.removeListeners(
        () => console.info('Removed background location listeners'),
        () => console.warn('Failed to remove background location listeners'),
      );
    } catch (error) {
      console.error(error);
    }

    /** Setup location listener */
    BackgroundGeolocation.onLocation(
      async location => {
        await this.onLocation(location);
        this.retryOnLocationListener = 0;
      },
      async error => {
        TrackJS.console.log('info', 'BackgroundGeolocation.onLocation error');
        TrackJS.track(error);
        if (this.retryOnLocationListener > 3) {
          TrackJS.track('onLocation retry > 3, reloading the app');
          window.location.reload();
          return;
        }
        await this.resetListeners();
        this.retryOnLocationListener += 1;
      },
    );
  }

  public async restartBBGO() {
    let state = await BackgroundGeolocation.stop();
    state = await BackgroundGeolocation.start();
    await this.resetListeners();
    BackgroundGeolocation.changePace(
      true,
      () => { },
      (err) => console.error(err),
    );
  }

}
