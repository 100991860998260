import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { NonServiceAction, NonServiceActionTypeEnum } from '../../models/non-service-action';

import { AppData } from 'src/app/data';
import { DispatcherService } from '../../services/dispatcher.service';
import { TranslateService } from '../../services/translate.service';
import { NotificationService } from '../../services/notification.service';
import { DateTime } from 'luxon';
import { catchError, combineLatest, distinctUntilChanged, EMPTY, interval, map, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-non-service-action',
  templateUrl: './non-service-action.component.html',
  styleUrls: ['./non-service-action.component.scss'],
})
export class NonServiceActionComponent implements OnInit, OnDestroy {

  public readonly data = inject(AppData);
  public readonly dispatcherService = inject(DispatcherService);
  private readonly translateService = inject(TranslateService);
  private readonly notify = inject(NotificationService);

  private readonly destroyed$ = new Subject<boolean>();

  @Input() nonServiceAction: NonServiceAction;
  public typeString = '';
  public scheduledTime: Date;
  public timeBasedProps: { inThePast: boolean, timeTo: string, canPerform: boolean };

  ngOnInit(): void {
    this.data.route$.pipe(
      distinctUntilChanged((pre, post) =>
        pre?.scheduledStart === post?.scheduledEnd
        && pre?.scheduledEnd === post?.scheduledEnd
        && pre?.pullOutEarlinessLimit === post?.pullOutEarlinessLimit
        && pre?.pullInEarlinessLimit === post?.pullInEarlinessLimit,
      ),
      map(route => {
        let limit: number = 0;
        switch (this.nonServiceAction.nonServiceActionType) {
          case NonServiceActionTypeEnum.pullOut:
            this.typeString = this.translateService.translate('LABEL.pullOut');
            this.scheduledTime = route.scheduledStart;
            limit = route.pullOutEarlinessLimit ?? 0;
            break;
          case NonServiceActionTypeEnum.pullIn:
            this.typeString = this.translateService.translate('LABEL.pullIn');
            this.scheduledTime = route.scheduledEnd;
            limit = route.pullInEarlinessLimit ?? 0;
            break;
            default:
              console.error('Missing or invalid action type');
              this.notify.error(this.translateService.translate('MESSAGE.missingActionType'));
              break;
            }

          this.timeBasedProps = this.getTimeBasedProps(this.scheduledTime, limit);
          return limit;
      }),
      switchMap(limit => combineLatest([ of(limit), interval(20_000) ])),
      tap(([ limit ]) => { this.timeBasedProps = this.getTimeBasedProps(this.scheduledTime, limit); }),
      takeUntil(this.destroyed$),
      catchError(err => {
        console.error(err);
        this.notify.error(this.translateService.translate('MESSAGE.genericError'), null, true);
        return EMPTY;
      }),
    ).subscribe();
  }

  async actionClicked(): Promise<void> {
    // in case we decide to add more actions
    try {
      switch (this.nonServiceAction.nonServiceActionType) {
        case NonServiceActionTypeEnum.pullOut:
          await this.dispatcherService.pullOut();
          break;
        case NonServiceActionTypeEnum.pullIn:
          await this.dispatcherService.pullIn();
          break;
        default:
          console.error('Missing or invalid action type');
          this.notify.error(this.translateService.translate('MESSAGE.missingActionType'));
          break;
      }
    } catch (error) {
      console.error(error);
      this.notify.error(this.translateService.translate('MESSAGE.genericError'));
    }
  }

  private getTimeBasedProps(time: Date, limit: number): { inThePast: boolean, timeTo: string, canPerform: boolean } {
    const now = DateTime.now();
    const actionDateTime = DateTime.fromJSDate(new Date(time));
    const eventTime = actionDateTime ? actionDateTime : now;
    const earliestActionTime = actionDateTime.minus({ seconds: limit ?? 0 });

    return {
      ...stop,
      inThePast: eventTime < now,
      timeTo: actionDateTime.toRelative(),
      canPerform: now >= earliestActionTime,
    };
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
