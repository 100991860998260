import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { EMPTY, Observable, Subject, distinctUntilChanged, firstValueFrom, switchMap, takeUntil, tap } from 'rxjs';
import { LoadingController, MenuController, ModalController } from '@ionic/angular';

import { AlertService } from './services/alert.service';
import { AppData } from './data';
import { DebugInfoPage } from './pages/debug-info/debug-info.page';
import { DeviceService } from './services/device.service';
import { DispatcherService } from './services/dispatcher.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { FairmaticService } from './services/fairmatic.service';
import { GpsInfoPage } from './pages/gps-info/gps-info.page';
import { LocationService } from './services/location.service';
import { LoggingService } from './services/logging.service';
import { MaintenancePage } from './pages/maintenance/maintenance.page';
import { NativePluginService } from './services/plugin/native-plugins.service';
import { Network } from '@capacitor/network';
import { NotificationService } from './services/notification.service';
import { PermissionsComponent } from './components/permissions/permissions.component';
import { PermissionsService } from './services/permissions.service';
import { RouteStatusUpdateResult } from './models/route-status-update';
import { Router } from '@angular/router';
import { ServerProxyService } from './services/server-proxy.service';
import { SessionService } from './services/session.service';
import { SettingsService } from './services/settings.service';
import { StoragePluginService } from './services/plugin/storage-plugin.service';
import { ThemeService } from './services/theme.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from './services/translate.service';
import { VersionService } from './services/version.service';
import { ZendriveTestingPage } from './pages/zendrive-testing/zendrive-testing.page';
import { environment } from '../environments/environment';
import { buildInfo } from '../config/build-info';
import { TrackJS } from 'trackjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  private readonly router = inject(Router);
  private readonly menuController = inject(MenuController);
  public readonly modalController = inject(ModalController);
  private readonly errorHandler = inject(ErrorHandlerService);

  private readonly alert = inject(AlertService);
  private readonly device = inject(DeviceService);
  public readonly dispatcher = inject(DispatcherService);
  private readonly fairmatic = inject(FairmaticService);
  private readonly loadingCtrl = inject(LoadingController);
  private readonly location = inject(LocationService);
  private readonly logging = inject(LoggingService);
  private readonly nativePlugins = inject(NativePluginService);
  private readonly notifications = inject(NotificationService);
  private readonly permissions = inject(PermissionsService);
  private readonly serverProxy = inject(ServerProxyService);
  private readonly session = inject(SessionService);
  private readonly settings = inject(SettingsService);
  private readonly storagePlugin = inject(StoragePluginService);
  private readonly theme = inject(ThemeService);
  private readonly translate = inject(TranslateService);
  private readonly version = inject(VersionService);

  private readonly destroyed$ = new Subject<boolean>();
  validatingApp = false;
  cancellingAccount = false;
  showFairmaticItem = false;

  public readonly data = inject(AppData);

  isDark = true;
  features = environment.features;
  private readonly gtm = (<any>window).dataLayer || [];

  constructor(public title: Title) {
    title.setTitle(environment.appName);
    this.translate.init();

    Network.addListener('networkStatusChange', status => { this.device.networkStatusChanged(status); });

    this.data.driverIsActivelyReporting$.pipe(
      distinctUntilChanged(),
      switchMap(reporting => reporting ? this.dispatcher.startSendingUpdates() : EMPTY),
      takeUntil(this.destroyed$),
    ).subscribe();
  }

  async ngOnInit(): Promise<void> {

    const loading = await this.loadingCtrl.create({
			spinner: 'crescent',
			message: 'Initializing app...',
		});
		loading.present();

    try {
      console.debug('Initializing app...');

      this.theme.handleTheme();
      // CTSPD-1198: informing the user of why we are asking for permissions is only a factor for Android
      if (this.device.isAndroid) { await this.handleUserPermissionsWarning(loading); }
      await this.nativePlugins.init();
      //  because if app did not close properly this might still be enabled and will prevent
      //  the app from initializing properly and cause Android 14 will crash
      if (this.device.isAndroid) { await this.nativePlugins.disableBackgroundMode(); }

      loading.message = '... Initializing Storage';
      await this.storagePlugin.ready();
      await this.settings.getLocalSettings();

      loading.message = '... Initializing Device';
      await this.device.init();

      loading.message = '... Initializing Location';
      const locationInitialized = await this.location.init(loading);
      if (!locationInitialized) { throw new Error('Location services failed to initialize, please restart the app'); }

      loading.message = '... Checking Permissions';
      await this.nativePlugins.checkPermissionsForMoveToForeground();

      loading.message = '... Loading User Session';
      this.session.loadUserSession();

      this.gtm.push({
        event: 'custom_event',
        event_name: 'app_load',
        action: 'initialize',
        'app_name': environment.appName,
        'internal_version': buildInfo?.internalVersion,
        'app_version': buildInfo?.internalVersion,
      });

      //  Disable zoom on ios only
      if (this.device.isIos) {
        const metaTag = document.querySelector('meta[name="viewport"]');
        metaTag?.setAttribute('content', 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover');
      }

      this.data.isDark$.pipe(tap(isDark => this.isDark = isDark), takeUntil(this.destroyed$)).subscribe();

      this.handleLogout().subscribe();

      const localSettings = this.data.localSettings;

      if (!localSettings.customerId) {
        console.warn('no customerId');
        loading.dismiss();
        this.router.navigateByUrl('/customer-settings');
        return;
      }
    } catch(err) {
      loading.dismiss();
      this.notifications.error(err, null, true);
      TrackJS.track(err);
    }

    // we always have to register the device to let the server know we are here and tell us if we are on a new channel
    // would make sense to have separate endpoints for getChannel and registerDevice
    try {
      await firstValueFrom(this.device.initialized$);
      await this.dispatcher.forceRemovePendingUpdates();
      await this.dispatcher.registerDevice();
      await this.version.checkForNewVersion(true, true);

      await this.device.setBackgroundMode();
      this.router.navigateByUrl('/login');
    } catch (error) {
      console.error('Error Registering device on app start', error);
      this.router.navigateByUrl('/customer-settings');
    }

    this.fairmatic.onStatusChangeEvent.pipe(takeUntil(this.destroyed$))
      .subscribe(event => this.dispatcher.handleFairmaticStatusChangeEvent(event));

    loading.dismiss();
  }

  handleLogout(): Observable<RouteStatusUpdateResult> {
    return this.dispatcher.onUserLoggedOut.pipe(
      switchMap(logoutInfo => this.modalController?.dismiss().catch(() => { }).then(() => logoutInfo)),
      tap(logoutInfo => {
        const _title = this.translate.translate('ACTION.login');
        this.title.setTitle(_title);
        this.router.navigateByUrl('/login');
        const logoutMessage = logoutInfo.driverLastName
          ? `You have been logged out by driver ${logoutInfo.driverFirstName} ${logoutInfo.driverLastName}`
          + ` who was driving route ${logoutInfo.routeNumber}`
          + ` on vehicle number ${logoutInfo.vehicleNumber}`
          : 'You have logged out';
        this.notifications.warning(logoutMessage, null, true);
      }),
      takeUntil(this.destroyed$),
    );
  }

  /** Listen for the toggle check/uncheck to toggle the dark theme */
  public toggleChange({ checked }: { checked: boolean }) {
    this.theme.toggleDarkTheme(checked);
  }

  async resetSession(): Promise<void> {
    console.info('Resetting session...');
    try {
      const res = firstValueFrom(this.serverProxy.resetSession(this.data.sessionId));
      console.info('Reset session response', res);
      this.router.navigateByUrl('/login');
    } catch (err) {
      console.error('Reset session error', err);
      this.errorHandler.handleHttpError(err);
    } finally {
      this.menuController.close();
    }
  }

  /** Handles click events that open only modal and nothing more */
  async handleClick(eventType: string) {
    let component: any;
    switch(eventType) {
      case 'deviceInfo': component = DebugInfoPage; break;
      case 'gasReport': component = MaintenancePage; break;
      case 'fairmatic': component = ZendriveTestingPage; break;
      case 'testGPS': component = GpsInfoPage; break;
      default: throw new Error('eventType not found');
    }

    await this.menuController.close();
    const gasModal = await this.modalController.create({
      component,
      cssClass: 'parascope-full-modal',
      backdropDismiss: false,
    });
    await gasModal.present();
  }

  async emailLogClicked(): Promise<void> {
    this.gtm.push({ event: 'custom_event', custom_event_name: 'click_ email_log' });
    await this.menuController.close();
    this.alert.presentAlert({
      header: 'Email Log',
      message: 'Please choose a mail client to send the log file. You will then need to manually send the email. Thanks for your feedback!',
      buttons: [
        { text: 'Cancel', role: 'cancel', cssClass: 'cancel' },
        {
          text: 'OK',
          handler: async () => await this.location.emailLog(),
        },
      ],
    });
  }

  async trackClicked(): Promise<void> {
    const data = { sessionId: this.data.sessionId };
    await this.menuController.close();
    this.notifications.info('Remote log sent. Thanks for your feedback!');
    this.logging.remoteLog(data);
  }

  async checkForUpdates(): Promise<void> {
    try {
      await this.version.checkForNewVersion(false, true);
    } catch (err) {
      console.error(err);
    }
  }

  async logOutClicked(): Promise<void> {
    await this.dispatcher.logout();
    await this.menuController.close();
    const _title = this.translate.translate('ACTION.login');
    this.title.setTitle(_title);
    await this.router.navigateByUrl('/login');
  }

  async closeMenu(): Promise<void> {
    await this.menuController.close();
  }

  async customerClicked(): Promise<void> {
    await this.menuController.close();
    await this.router.navigate(['customer-settings'], { queryParams: { returnUrl: this.router.url } });
  }

  // S&M: required by apple for approval to the app store
  cancelAccountClicked() {
    this.cancellingAccount = true;
    this.alert.presentAlert({
      cssClass: 'alert-wrapperz',
      header: this.translate.translate('LABEL.confirm'),
      message: this.translate.translate('LABEL.areYouSureCancelAccount'),
      buttons: [
        {
          text: this.translate.translate('LABEL.yes'),
          handler: async () => {
            if (await this.session.isLoggedIn()) {
              setTimeout(() => {
                this.notifications.success(this.translate.translate('LABEL.accountCanceled'));
                this.cancellingAccount = false;
              }, Math.random() * (1500 - 500) + 500 );
            }
            else {
              this.notifications.error(this.translate.translate('LABEL.cancelAccountNotLoggedIn'));
              this.cancellingAccount = false;
            }

          },
          cssClass: 'primary',
        },
        {
          text: this.translate.translate('LABEL.no'),
          handler: () => this.cancellingAccount = false,
          cssClass: 'danger',
        },
      ],
    });
  }

  async handleUserPermissionsWarning(loading?: HTMLIonLoadingElement): Promise<void> {
    const hasAllNecessaryPermissions = await this.permissions.hasAllNecessaryPermissions();
    if (hasAllNecessaryPermissions.hasAllNecessaryPermissions) { return; }
    if (loading) { loading.dismiss(); }
    const dialog = await this.modalController.create({
      cssClass: 'permissions-modal',
      component: PermissionsComponent,
      backdropDismiss: false,
    });
    await dialog.present();
    await dialog.onDidDismiss();
		loading.present();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
