<ion-card class="nsa-card">
  <ion-grid>
    <ion-row>
      <ion-col size="12" sizeSm="6">
        <ion-card-title>
          <span [ngClass]="{ 'late-stop' : timeBasedProps?.inThePast}">
            {{ scheduledTime | amDateFormat:'HH:mm' }}
          </span>
          <span>{{ 'LABEL.scheduled' | translate }} {{ typeString }}</span>
        </ion-card-title>
        <ion-card-subtitle>
          {{ typeString }} {{ timeBasedProps?.timeTo }}
        </ion-card-subtitle>
      </ion-col>
      <ion-col size="12" sizeSm="6">
        <ion-card-content>
          <ion-button
            *ngIf="timeBasedProps?.canPerform"
            color="primary"
            expand="block"
            (click)="actionClicked()"
          >
            <span>{{ typeString }}</span>
          </ion-button>
        </ion-card-content>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-card>
