<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'LABEL.vehicleInspChecklist' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="wrapper">

  <!-- START INSPECTION -->
  <ng-container *ngIf="state === 'inspection'">
    <ion-card *ngFor="let inspectionGroup of inspectionGroups" class="group">

      <ion-card-header>
        <ion-card-title>{{ inspectionGroup.serviceCategory }}</ion-card-title>
      </ion-card-header>

      <ion-card-content>
        <section *ngFor="let inspection of inspectionGroup.inspectionItems">

          <h2>{{ inspection.description  }}</h2>

          <ion-segment
            mode="ios"
            *ngIf="inspection.questionType === 0"
            (ionChange)="inspectionValueClicked($event, inspection)"
            [value]="inspection.result"
          >
              <ng-container *ngTemplateOutlet="useAlternateInspectionResultValues ? AlternateInspectionButtons : DefaultInspectionButtons" />
          </ion-segment>

          <ng-template #DefaultInspectionButtons>
            <ion-segment-button
              color="success"
              class="segment-ok"
              [value]="InspectionResultEnum.Pass"
            >
              <ion-label>{{ 'ACTION.ok' | translate}} </ion-label>
            </ion-segment-button>

            <ion-segment-button
              color="warning"
              class="segment-service-needed"
              [value]="InspectionResultEnum.ServiceDueSoon"
            >
              <ion-label>
                <span class="hidden-insp">{{ 'ACTION.serviceDueSoon' | translate }}</span>
                <span class="show-insp">{{ 'ACTION.serviceDueSoonDue' | translate }}</span>
              </ion-label>
            </ion-segment-button>

            <ion-segment-button
              color="danger"
              class="segment-service-critical"
              [value]="InspectionResultEnum.ServiceCritical"
            >
              <ion-label>
                <span class="hidden-insp">
                {{ 'ACTION.serviceCritical' | translate }}
                </span>
                <span class="show-insp">
                {{ 'ACTION.serviceCriticalCritical' | translate }}
                </span>
              </ion-label>
            </ion-segment-button>
          </ng-template>

          <ng-template #AlternateInspectionButtons>
            <ion-segment-button
              color="success"
              class="segment-ok"
              [value]="InspectionResultEnum.Pass"
            >
              <ion-label>{{ 'ACTION.ok' | translate}} </ion-label>
            </ion-segment-button>

            <ion-segment-button
              color="danger"
              class="segment-service-critical"
              [value]="InspectionResultEnum.ServiceDueSoon"
            >
              <ion-label>
                <span>{{ 'ACTION.fail' | translate }}</span>
              </ion-label>
            </ion-segment-button>
          </ng-template>

          <ion-item fill="outline" *ngIf="inspection.questionType == 1">
            <ion-label position="stacked">{{ inspection.description }}</ion-label>
            <ion-input
              aria-label="inspection"
              type="text"
              inputmode="text"
              minlength="1"
              (ionInput)="inspectionValueChanged(inspection, $event.target.value)"
              [placeholder]="'PLACEHOLDER.enterValue' | translate"
              [value]="inspection.textResult"
              required>
            </ion-input>
          </ion-item>

          <ion-item *ngIf="useInspectionItemComment">
            <ion-label position="stacked">{{ 'LABEL.comment' | translate }}</ion-label>
            <ion-input
              [attr.aria-label]="'LABEL.comment' | translate"
              type="text"
              inputmode="text"
              maxlength="280"
              (ionInput)="inspectionCommentChanged(inspection, $event.target.value)"
              [placeholder]="'PLACEHOLDER.enterValue' | translate"
              [value]="inspection.comment">
            </ion-input>
          </ion-item>

        </section>
      </ion-card-content>
    </ion-card>
  </ng-container>
  <!-- END START INSPECTION -->

  <!-- CRITICAL CONFIRMATION -->

  <div *ngIf="state === 'criticalWarning'" class="warning">
    <ion-row>
      <ion-col>
        <h3>{{ 'TITLE.criticalFailures' | translate }}</h3>
      </ion-col>
    </ion-row>
    <ion-list>
      <ion-item *ngFor="let failure of getFailures()">
        <ion-label>{{ failure.description }}</ion-label>
      </ion-item>
    </ion-list>
  </div>

</ion-content>

<ion-footer>
  <ion-row *ngIf="state === 'inspection'">
    <ion-col>
      <ion-button
        type="submit"
        color="primary"
        expand="block"
        (click)="submitInspection()"
        [disabled]="!canSubmit"
      >
        {{  'ACTION.submit' | translate }}
        <ion-icon name="checkmark" slot="end"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col *ngIf="data.route.inspectionBypassEnabled ?? false">
      <ion-button
        type="button"
        color="warning"
        expand="block"
        (click)="bypassInspection()"
      >
        {{  'ACTION.bypass' | translate }}
        <ion-icon name="close" slot="end"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>

  <ng-container *ngIf="state === 'criticalWarning'">
    <ion-row>
      <ion-col>
        <ion-button
          type="submit"
          color="medium"
          expand="block"
          (click)="cancelCriticalWarning()"
          [disabled]="!canSubmit"
        >
          {{  'ACTION.cancel' | translate }}
          <ion-icon name="close-outline" slot="end"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-button
          type="submit"
          color="danger"
          expand="block"
          (click)="submit(true)"
          [disabled]="!canSubmit"
        >
          {{  'ACTION.confirm' | translate }}
          <ion-icon name="checkmark" slot="end"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ng-container>
</ion-footer>
