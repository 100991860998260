import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { GroupedStop, ScheduledEvent } from '../job-list/job-list.component';
import { Job, JobType } from 'src/app/models/job';

import { RouteProviderService } from 'src/app/services/route-provider.service';
import { environment } from 'src/environments/environment';
import { LocationService } from 'src/app/services/location.service';
import { ModalController } from '@ionic/angular';
import { GroupOnTheWayDialog } from './group-on-the-way/group-on-the-way.dialog';
import { AppData } from 'src/app/data';
import { GroupArriveDialog } from './group-arrive/group-arrive.dialog';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { GroupPerformDialog } from './group-perform/group-perform.dialog';
import { DateTime } from 'luxon';
import { ScheduledFlagStop } from 'src/app/models/flag-stop';
import { NonServicePeriod } from 'src/app/models/non-service-period';
import { EMPTY, Subject, catchError, interval, takeUntil, tap } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslateService } from 'src/app/services/translate.service';
import { AlertService } from 'src/app/services/alert.service';
import { GroupNotifyDialog } from './group-notify/group-notify.dialog';
import { buildCallRequest } from 'src/app/models/call-request';
import { UtilityService } from 'src/app/services/utility.service';
import { GroupDetailsDialog } from './group-details/group-details.dialog';

@Component({
  selector: 'app-grouped-stops',
  templateUrl: './grouped-stops.component.html',
  styleUrls: ['./grouped-stops.component.scss'],
})
export class GroupedStopsComponent implements OnInit, OnDestroy {

  private readonly alertService = inject(AlertService);
  private readonly dispatcherService = inject(DispatcherService);
  private readonly locationService = inject(LocationService);
  private readonly modalController = inject(ModalController);
  private readonly notificationService = inject(NotificationService);
  private readonly routeProvider = inject(RouteProviderService);
  private readonly translateService = inject(TranslateService);
  private readonly utilityService = inject(UtilityService);

  public readonly data = inject(AppData);

  private readonly destroyed$ = new Subject<boolean>();

  @Input() isDetailView: boolean = false;
  @Input() set group(group: ScheduledEvent<GroupedStop>) {

    this.pickupCount = group.jobs.filter(j => j.jobType === JobType.Pickup).length;
    this.dropOffCount = group.jobs.filter(j => j.jobType === JobType.DropOff).length;
    this.jobs = this.updateTimeBasedPropertiesInAllItems(group.jobs);
    this.baseJob = this.jobs?.[0] || <ScheduledEvent<Job>>{};
    this.specialAssistances = Array.from(new Set(group.jobs.map(j => j.specialAssistances).flat())).filter(specialAssistance => !!specialAssistance);

    this.mobilities = group.jobs.reduce((acc, j) => {
      if (j.ambulatorySeats && !j.liftRequired && !acc.includes('ambulatory')) { acc = [ ...acc, 'ambulatory' ]; }
      if (j.wheelchairSeats && !acc.includes('wheelchair')) { acc = [ ...acc, 'wheelchair' ]; }
      if (j.stretcherCount && !acc.includes('stretcher')) { acc = [ ...acc, 'stretcher' ]; }
      if (j.liftRequired && !acc.includes('lift')) { acc = [ ...acc, 'lift' ]; }
      if (acc.includes('ambulatory') && acc.includes('lift')) { acc = acc.filter(m => m !== 'ambulatory'); }
      return acc;
    }, []);

    //  Determine which actions to show based on what is in the job list
    //  showJobActionButtons -- show / hide the bank of buttons so we only have to check the set once
    this.showJobActionButtons = group.jobs?.some(j => j.jobEnabled && !this.data.currentRouteIsPreview && !j.flagStopId && j.isAtNextLocation);
    //  individual buttons
    this.showOnTheWayButton   = group.jobs?.some(j => !this.onTheWayCompleted(j));
    this.showArriveButton     = group.jobs?.some(j => this.onTheWayCompleted(j) && !j.arriveTime);
    this.showPerform          = group.jobs?.some(j => this.onTheWayCompleted(j) && j.arriveTime);

    this.showNavigateButton = group.jobs?.some(j => (j.jobEnabled && !this.data.currentRouteIsPreview) && j.isAtNextLocation);
    this.showNotifyButton = group.jobs?.some(j =>
      (j.jobEnabled && !this.data.currentRouteIsPreview)
      && j.isAtNextLocation && j.jobType == 1
      && environment.features.notifyPassenger);
  };

  public jobs: ScheduledEvent<Job>[];
  public baseJob = {} as Job;
  public pickupCount = 0;
  public dropOffCount = 0;
  public specialAssistances = [];
  public mobilities = [];

  public showJobActionButtons = false;
  public showOnTheWayButton = false;
  public showArriveButton = false;
  public showPerform = false;
  public showNavigateButton = false;
  public showNotifyButton = false;

  ngOnInit() {
    interval(20_000).pipe(
      tap(() => { this.jobs = this.updateTimeBasedPropertiesInAllItems(this.jobs); }),
      takeUntil(this.destroyed$),
      catchError(err => { console.error(err); return EMPTY; }),
    ).subscribe();
  }

  private onTheWayCompleted(j: ScheduledEvent<Job>) {
    // If there is a value in onTheWayTime, it's completed otherwise,
    // else the OTW is disabled then we skip this check
    return this.data.route.allowOnTheWay && environment.features.onTheWayButton && j.jobType === JobType.Pickup ? !!j.onTheWayTime : true;
  }

  public async showGroupDetails(): Promise<void> {
    const modal = await this.modalController.create({
      component: GroupDetailsDialog,
      componentProps: { jobs: this.jobs },
    });
    await modal.present();
    //  selected only returns the job IDs
    const { data: selected } = await modal.onDidDismiss();
    if (environment.features.onlyAtNextLocationEnabled) {
      this.utilityService.scrollTop$.next(true);
    }
    if (!selected) { return; }
  }

  public async onGroupAction(action: string, event: Event) {
    event.stopPropagation();
    console.debug('GROUP STOP ACTION:', action);
    switch (action) {
      case 'onTheWay': await this.handleOnTheWay(); break;
      case 'arrive': await this.handleArrive(); break;
      case 'performGroupedStop': await this.handlePerform(); break;
      case 'navigate': this.handleNavigate(); break;
      case 'previewLocation': this.handleLocationPreview(); break;
      case 'notify': await this.handleNotify(); break;
      default: throw new Error('unknown action type');
    }
    if (environment.features.onlyAtNextLocationEnabled) {
      this.utilityService.scrollTop$.next(true);
    }
  }

  private async handleOnTheWay(): Promise<void> {
    //  filter for jobs on the list to mark as on the way
    const _jobs = this.jobs.filter(j => j.jobEnabled && j.isAtNextLocation && !j.onTheWayTime && j.jobType === JobType.Pickup);
    const modal = await this.modalController.create({
      component: GroupOnTheWayDialog,
      componentProps: { jobs: _jobs },
    });
    await modal.present();
    //  selected only returns the job IDs
    const { data: selected } = await modal.onDidDismiss();
    if (!selected) { return; }

    const selectedJobs = this.jobs.filter(j => selected.includes(j.rideId));
    const gpsReport = this.data.gpsReport;
    const onTheWays = await Promise.all(
      selectedJobs.map(async j => {
        let estimatedTimeOfArrival: Date = null;
        if (j.location.coordinate && environment.features.enableGoogleMapsApiCall) {
          const { latitude, longitude } = j.location.coordinate;
          estimatedTimeOfArrival = await this.locationService.calculateArrivalTime(latitude, longitude);
        }
        return { rideId: j.rideId, estimatedTimeOfArrival, gpsReport };
      }),
    );
    await this.routeProvider.reportJobsOnTheWay(onTheWays);
    if (environment.features.onlyAtNextLocationEnabled) {
      this.utilityService.scrollTop$.next(true);
    }
  }

  private async handleArrive(): Promise<void> {
    //  filter for jobs on the list to mark as arrived
    const _jobs = this.jobs.filter(j =>
      j.jobEnabled
      && ((j.jobType === JobType.Pickup && j.isAtNextLocation && (j.onTheWayTime || !environment.features.onTheWayButton ) && !j.arriveTime)
      || j.jobType === JobType.DropOff
    ));
    if (!_jobs?.length) {
      this.notificationService.error(this.translateService.translate('MESSAGE.noJobsToArrive'));
      return;
    }

    //  check for proximity to arrive locations
    //  only checking first on the list because we already know each stop in the group is within tolerance of each other
    const hasArrived = this.locationService.hasArrived(this.baseJob.location.coordinate);

    //  To far from stop, confirm arrive
    if (!hasArrived) {
      const proceedWithArrive = await new Promise(resolve => {
        this.alertService.presentAlert({
          cssClass: 'alert-wrapperz',
          header: this.translateService.translate('LABEL.confirmArrival'),
          message: this.translateService.translate('LABEL.groupNotInRange'),
          buttons: [{
            text: this.translateService.translate('LABEL.yes'),
            cssClass: 'primary',
            handler: () => resolve(true),
          }, {
            text: this.translateService.translate('LABEL.no'),
            cssClass: 'danger',
            handler: () => resolve(false),
          }],
        });
      });
      //  user did not confirm, stop here
      if (environment.features.onlyAtNextLocationEnabled) {
        this.utilityService.scrollTop$.next(true);
      }
      if (!proceedWithArrive) { return; }
    }

    // skip the rider selection dialog
    if (!environment.features.showGroupArriveSelectionDialog) {
      await this.dispatcherService.arriveJobs(_jobs);
      return;
    }

    const modal = await this.modalController.create({
      component: GroupArriveDialog,
      componentProps: { jobs: _jobs },
    });
    await modal.present();
    //  selected only returns the job IDs
    const { data: selected } = await modal.onDidDismiss();
    if (!selected) { return; }
    const selectedJobs = selected.map((selectedJobId: number) => this.jobs.find(j => j.rideId === selectedJobId));
    await this.dispatcherService.arriveJobs(selectedJobs);
  }

  private async handlePerform(): Promise<void> {
    const _jobs = this.jobs.filter(j => j.jobEnabled && j.isAtNextLocation && this.onTheWayCompleted(j) && j.arriveTime);
    const modal = await this.modalController.create({
      component: GroupPerformDialog,
      componentProps: { jobs: _jobs },
    });
    await modal.present();
    await modal.onDidDismiss();
    if (environment.features.onlyAtNextLocationEnabled) {
      this.utilityService.scrollTop$.next(true);
    }
  }

  private handleNavigate() {
    // if base job does not have coordinates, find a job that has some in the list
    const coordinates =  this.baseJob.location.coordinate || this.jobs.find(j => !!j.location.coordinate)?.location.coordinate;
    if (!coordinates) {
      // there are no jobs with location set, so notify the user
      this.notificationService.warning(this.translateService.translate('LABEL.cannotNavigate'));
      return;
    }
    this.locationService.navigateToDestination(coordinates);
  }

  private handleLocationPreview() {
    // if base job does not have coordinates, find a job that has some in the list
    const job =  this.baseJob.location.coordinate ? this.baseJob : this.jobs.find(j => !!j.location.coordinate);
    if (!job) {
      // there are no jobs with, so notify the user
      this.notificationService.warning(this.translateService.translate('LABEL.cannotOpenNoGeo'));
      return;
    }
    this.locationService.previewLocation(job.location.coordinate, job.location.name);
  }

  private async handleNotify(): Promise<void> {
    const _jobs = this.jobs.filter(j => j.jobEnabled && j.isAtNextLocation && j.jobType === JobType.Pickup);
    const modal = await this.modalController.create({
      component: GroupNotifyDialog,
      componentProps: { jobs: _jobs },
    });
    await modal.present();
    //  selected only returns the job IDs
    const { data: selected } = await modal.onDidDismiss();
    if (!selected) { return; }

    const res: (string | null)[] = await Promise.all(
      selected.map((selectedJobId: number) => {
        const job = this.jobs.find(j => j.rideId === selectedJobId);
        if (!job) {
          console.error(`Attempting to send contact request for passenger, job ${selectedJobId} not found`);
          return null;
        }
        const request = buildCallRequest({ job, gpsReport: this.data.gpsReport });
        return this.dispatcherService.callRequest(request)
          .then(() => this.utilityService.handleName(job.riderFirstName, job.riderLastName))
          .catch(err => { console.error(err); return null; });
      }),
    );
    const contacted = res.filter(name => !!name);
    if (!contacted) { this.notificationService.error(this.translateService.translate('GENERIC.error')); }
    const contactedString = contacted.reduce((acc, name, i) => i < contacted.length - 1 ? `${acc}, ${name}` : `${acc}, and ${name}`, '');
    this.notificationService.success(`${this.translateService.translate('LABEL.reqSent')} ${contactedString}`);
  }

  /** Time utilities */

  private updateTimeBasedPropertiesInAllItems(items: ScheduledEvent[] = []): Job[] {
    return items.map(item => {
      const time = this.getTime(item);
      return this.updateTimeBasedProperties(item, time);
    });
  }

  private updateTimeBasedProperties(stop: ScheduledEvent, time: DateTime): Job {
    const now = DateTime.now();
    const eventTime = time ? time : now;
    const inThePast = eventTime < now;
    return <Job>{ ...stop, inThePast, timeToStop: eventTime.toRelative() };
  }

  private getTime(item: ScheduledEvent): DateTime | null {
    let time;
    switch(item.vmType) {
      case 'job': time = (<Job>item).scheduledTime; break;
      case 'scheduledFlagStop': time = (<ScheduledFlagStop>item).arrivalTime; break;
      case 'nonServicePeriod': time = (<NonServicePeriod>item).scheduledStart; break;
      case 'nonServicePeriod': time = (<NonServicePeriod>item).scheduledStart; break;
      default: time = null;
    }
    if (item.vmType === 'group') { return (<GroupedStop>item).time; }
    if (!time) { return null; }
    const dateTime = DateTime.fromISO(time);
    return dateTime.isValid ? dateTime : null;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
