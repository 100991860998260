<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss($event)" size="default" color="secondary" fill="outline">
        <ion-icon name="arrow-back" slot="start"></ion-icon>
        <span class="hidden-md">&nbsp; {{ 'ACTION.back' | translate }}</span>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'TITLE.jobDetails' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="modal-content-wrapper ion-padding">

  <app-job [job]="job" [isDetailView]="true" (closeParent)="dismiss($event)"></app-job>

  <ion-card *ngIf="dropoffJob" class="job-card" [class.pickup]="false">
    <ion-card-header class="job-card-header">
      <div>
        <ion-card-title class="title">
          <span class="job-type">
            {{ ('LABEL.scheduledDropoff' | translate) }}
          </span>
          <span class="job-rider" [ngClass]="{ 'late-stop' : dropoffJob.inThePast }">
            - {{ !dropoffJob.scheduledTime ? '00:00' : dropoffJob.scheduledTime | amDateFormat:'HH:mm' }}
          </span>
        </ion-card-title>
      </div>
    </ion-card-header>
    <ion-card-content>
      <div>
        <address class="address" *ngIf="dropoffJob.location.state">
          <strong>{{ dropoffJob.location.name }}</strong> - {{ dropoffJob.location.address }} {{ dropoffJob.location.address2 }}
          <br>{{ dropoffJob.location.city }}, {{ dropoffJob.location.state }}, {{ dropoffJob.location.zipCode }}
          <ng-container *ngIf="!!dropoffJob.dropoffPhone">
            <br>
            <a href="tel:+1{{ dropoffJob.dropoffPhone }}">
              {{ dropoffJob.dropoffPhone }}
            </a>
          </ng-container>
        </address>
      </div>
    </ion-card-content>
  </ion-card>


  <ion-card *ngIf="job.riderImageId">
    <ion-card-header>
      <ion-card-title>{{ 'LABEL.riderImage' | translate }}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ng-container *ngIf="(image || (hasNetwork$ | async)) else noNetwork">
        <img class="view-image" [src]="image">
        <ion-text color="danger" *ngIf="errorMessage">{{ errorMessage }}</ion-text>
      </ng-container>
      <ng-template #noNetwork>You are currently offline, image will be available once you are back online.</ng-template>
    </ion-card-content>
  </ion-card>

  <ion-card *ngIf="job.rideComments">
    <ion-card-header>
      <ion-card-title>{{ 'LABEL.reservationSpecialDir' | translate }}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <p class="comments">{{ job.rideComments }}</p>
    </ion-card-content>
  </ion-card>

  <ion-card *ngIf="job.riderComments">
    <ion-card-header>
      <ion-card-title>{{ 'LABEL.riderSpecialDir' | translate }}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <p class="comments">{{ job.riderComments }}</p>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-content>
      <ion-list lines="full" class="details">

        <ion-item>
          <ion-note>{{ 'LABEL.fare' | translate }}:</ion-note>
          <ion-label>{{ calculateJobFare() | currency:'USD':true:'1.2-2' }}</ion-label>
        </ion-item>

        <ion-item>
          <ion-note>{{'LABEL.riderPhone' | translate}}:</ion-note>
          <ion-label>
            <a *ngIf="job.riderPhone" [href]="'tel:+1' + job.riderPhone">{{ job.riderPhone }}</a>
            <ng-container *ngIf="!job.riderPhone">None</ng-container>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-note>{{ 'LABEL.account' | translate }}:</ion-note>
          <ion-label>{{ job.accountCode }}</ion-label>
        </ion-item>

        <ion-item>
          <ion-note>{{ 'LABEL.auth' | translate }} #:</ion-note>
          <ion-label>{{ job.authNumber || 'None' }}</ion-label>
        </ion-item>

        <ion-item *ngIf="showClientId">
          <ion-note>{{ riderIdLabel | translate }} #:</ion-note>
          <ion-label>{{ job.riderId || 'None' }}</ion-label>
        </ion-item>

        <ion-item>
          <ion-note>{{ scheduledTimeLabel | translate }}:</ion-note>
          <ion-label>{{ !job.scheduledTime ? '00:00' : job.scheduledTime | amDateFormat:'HH:mm' }}</ion-label>
        </ion-item>

        <ion-item>
          <ion-note>{{ priorityLabel | translate }}:</ion-note>
          <ion-label>{{ job.priorityTime | amDateFormat:'HH:mm' }} {{job.priority}}</ion-label>
        </ion-item>

        <ion-item>
          <ion-note>{{ 'LABEL.escorts' | translate }}:</ion-note>
          <ion-label>{{ job.escorts }}</ion-label>
        </ion-item>

        <ion-item>
          <ion-note>{{ 'LABEL.children' | translate }}:</ion-note>
          <ion-label>{{ job.numberOfChildren }}</ion-label>
        </ion-item>

        <ion-item>
          <ion-note>{{ 'LABEL.ambulatorySeats' | translate }}:</ion-note>
          <ion-label>{{ job.ambulatorySeats }}</ion-label>
        </ion-item>

        <ion-item>
          <ion-note>{{ 'LABEL.wheelchairStations' | translate }}:</ion-note>
          <ion-label>{{ job.wheelchairSeats }}</ion-label>
        </ion-item>

      </ion-list>
    </ion-card-content>
  </ion-card>

</ion-content>
