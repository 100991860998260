//Note: do not check in over this. When we run locally, we will be able to identify local testing using this.
export const buildInfo = {
  name: 'com.cts.ParaScope',
  ionicAppId: '6b9ec9dc',
  ionicAppChannel: 'configurable',
  publicVersion: '8.1.6',
  baselineBuildId: 8610226,
  date: '2025-01-16 18:47:42.132Z',
  internalVersion: '25.16.1127',
};
